<template>
    <div class="scroll mt-4 relative" ref="scroller">
      <!-- Note: this uses the parent to determine the styling of the children in the list (via slots)
           See: https://v3.vuejs.org/guide/component-slots.html#scoped-slots -->
        <div v-for="(item, index) in items" :key="index" :ref="'slot_'+index">
          <slot :item="item" />
        </div>
        
        <!-- <div class="block-shadow absolute right-0 w-12 bg-gradient-to-r from-transparent to-black"></div> -->
    </div>
    <div class="hidden sm:flex pt-6 w-full justify-between align-middle z-30">
        <div>
            <a v-if="!isAtStart && !everythingFits" @click.prevent="moveLeft" class="text-white cursor-pointer">
              <img src="@/assets/icons/horizontalScroller/buttonLeft.svg" alt="">
            </a>
        </div>
        <div>
            <a v-if="!isAtEnd && !everythingFits" @click.prevent="moveRight" class="text-white cursor-pointer">
              <img src="@/assets/icons/horizontalScroller/buttonRight.svg" alt="">
            </a>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: "HorizontalScroller",
  props: {items: Array},
  data() {
    return {
      position: 0,
      // for triggerhack see: https://stackoverflow.com/questions/42678983/vue-js-computed-property-not-updating
      triggerHack: false,
      debouncedForceUpdateComputed: () => {},
    }
  },
  mounted(){
    this.forceUpdateComputed();

    const debounceTime = 200;
    this.debouncedForceUpdateComputed = debounce(this.forceUpdateComputed, debounceTime)
    window.addEventListener('resize', this.debouncedForceUpdateComputed);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedForceUpdateComputed);
  },
  watch: {
    xoffset (offset) {
      // move the actual scroll position if xoffset changes
      const scroller = this.$refs.scroller;
      scroller.scrollLeft = offset;
    },
  },
  computed: {
    xoffset () {
      // calculate the combined (pixel) width of all the items left of the current item (= this.position)
      let offset = 0;
      const element_max = this.position;
      for(let i = 0; i < element_max; i++){
        const nth_element = this.$refs['slot_' + i];
        offset += nth_element.scrollWidth;
      }
      return offset;
    },
    length () {
      return this.items.length;
    },
    isAtStart() {
      return this.position <= 0;
    },
    isAtEnd() {
      this.triggerHack
      const scroller = this.$refs.scroller;
      if (!scroller){
        return this.isLastItem;
      }
      // check if last item   OR 
      // the (visible) window width + the current offset exceeds the width of the scroll container (incl. non-visible erea)
      return this.isLastItem || scroller.offsetWidth + this.xoffset >= scroller.scrollWidth;
    },
    isLastItem() {
      return this.position +1 >= this.length;
    },
    everythingFits() {
      this.triggerHack
      const scroller = this.$refs.scroller;
      if (!scroller){
        return false;
      }
      return  scroller.offsetWidth === scroller.scrollWidth;
    }
  },
  methods: {
    moveRight () {
      if (!this.isAtEnd){
        this.position += 1;
      }
    },
    moveLeft () {
      if (!this.isAtStart){
        this.position -= 1;
      }
    },
    forceUpdateComputed () {
      this.triggerHack = !this.triggerHack;
    }
  },
}
</script>

<style scoped>
.scroll {
  scroll-behavior: smooth;
  display: flex; 
  flex-wrap: nowrap; 
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; 
}

.scroll::-webkit-scrollbar {
  display: none; 
}

.block-shadow {
    height: 400px;
}
</style>