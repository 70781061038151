<template>
    <div>       
        <div class="pt-4 md:pt-6 px-4 md:px-10 flex flex-row md:flex-row-reverse">
            <input class="py-2 px-2 md:py-3 md:px-3 border rounded text-white text-sm font-medium border-gray-500 bg-black focus:bg-gray-900 hover:bg-gray-900 transition-all duration-200" type="text" v-model="searchquery" :placeholder="t('search')">
        </div>

        <div class="pb-16 px-4 sm:px-4 md:px-10">
            <h3 class="heading-1 mt-6">{{ t('new_experts') }}</h3>
            <template v-if="new_experts.length != 0">
                <HorizontalScroller :items="new_experts">
                    <template v-slot:default="{ item }">
                        <router-link  :to="{name: 'expert.show', params: {id: item.id }}" class="min-w-lg">
                            <ExpertItem :expert="item" class="expert-item mr-6" />
                        </router-link>
                    </template>
                </HorizontalScroller>
            </template>
            <template v-else>
                <p class="text-gray-300 text-sm mt-2 font-medium">{{ t('no_new_experts') }}</p>
            </template>
        </div>
        
        <template v-for="[role, experts] in grouped_experts" :key="role">
            <div class="bg-gradient-to-b from-gradient-scorelitgray to-transparent py-16 px-4 sm:px-4 md:px-10">
                <router-link :to="{name: 'expert.index', params: {role}}">
                    <h3 class="heading-1 capitalize">{{ expertRolePlural(role) }}</h3>
                </router-link>
                <template v-if="experts.length != 0">
                    <HorizontalScroller :items="experts">
                        <template v-slot:default="{ item }">
                            <router-link  :to="{name: 'expert.show', params: {id: item.id }}" class="min-w-lg ">
                                <ExpertItem :expert="item" class="expert-item mr-6" />
                            </router-link>
                        </template>
                    </HorizontalScroller>
                    <router-link :to="{name: 'expert.index', params: {role}}">
                        <div v-if="experts.length != 0" class="mt-10 flex items-center group cursor-pointer transition duration-200">
                            <span class="text-gray-300 text-sm font-medium group-hover:text-white transition duration-200">{{ t('show_all_in_category', {category: expertRolePlural(role)}) }}</span>
                            <i class="ml-3"> 
                                <svg class="w-6 h-6 text-gray-300 cursor-pointer transition duration-200 group-hover:text-white" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"/>
                                </svg>
                            </i>
                        </div> 
                    </router-link>
                </template>
                <template v-else>
                    <!-- NB: this is never shown -->
                    <p class="text-gray-300 text-sm mt-2 font-medium">{{ t('not_available_in_category', {category: expertRolePlural(role)}) }}</p>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import HorizontalScroller from "@/components/dashboard/HorizontalScroller";
import ExpertItem from "@/components/dashboard/ExpertItem";
import { indexExperts } from "@/helpers/api/ExpertHelper";
import { fuzzyExpertFilter } from "@/helpers/ExpertFilter";
import { expertRolePlural } from "@/models/ExpertFactory";
import useI18n from "@/modules/globalI18n";

// credit: https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
const groupBy = (x, f) => x.reduce((a, b) => ( (a[f(b)] ||= []).push(b), a), {});

export default {
    name: "Dashboard",
    setup() {
        return useI18n({
            viewPrefix: "Dashboard"
        });
    },
    components: { HorizontalScroller, ExpertItem },
    created(){
        indexExperts()
            .then((coaches) => {
                console.log(coaches);
                this.experts = coaches;
            })
            .catch(err => {
                console.error(err);
            });
    },
    data(){
        return {    
            experts: [],
            searchquery: "",
        };
    },
    computed: {
        filterd_sorted_experts: function () {
            const sortByTimeDesc = (a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime();

            const filterdExperts = this.experts.filter(fuzzyExpertFilter(this.searchquery));

            return filterdExperts.sort(sortByTimeDesc);
        },
        new_experts: function (){
            const PLAYERS_AMOUNT = 10;

            return this.filterd_sorted_experts.slice(0, PLAYERS_AMOUNT);
        },
        grouped_experts: function () {
            const sortByRoleId = (exp1, exp2) => exp1.getRoleId() - exp2.getRoleId();
            
            const expertWithRole = this.filterd_sorted_experts
                .filter(expert => expert.getRole())
                .sort(sortByRoleId);

            return Object.entries(groupBy(expertWithRole, expert => expert.getRole()));
        },
    },
    methods:{
        expertRolePlural
    },
}
</script>
<style scoped>
.expert-item {
    flex: 0 0 auto;
}
</style>